/* App.css */

/* General reset and styling */
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f9;
  color: #333;
}

div {
  margin: 20px;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

/* Styling for the table */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

table thead tr {
  background-color: #6200ea;
  color: #fff;
}

table th,
table td {
  padding: 15px;
  text-align: left;
  border: 1px solid #ddd;
}

table th {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

table tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

table tbody tr:nth-child(even) {
  background-color: #e7e7ef;
}

table tbody tr:hover {
  background-color: #d1c4e9;
}

tfoot {
  background-color: #6200ea;
  color: #fff;
  font-weight: bold;
}

tfoot td {
  font-size: 14px;
}

/* Add responsiveness */
@media (max-width: 768px) {
  table {
    font-size: 12px;
  }

  table th,
  table td {
    padding: 10px;
  }
}

/* Add some spacing for the headings */
th {
  text-align: left;
}

/* Highlight totals row */
tfoot tr {
  background-color: #4527a0;
}